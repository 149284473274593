import article_11_12_24 from "./articles/11-12-24/News-11-12-24";
import article_11_17_24 from "./articles/11-17-24/News-11-17-24";
import article_11_24_24 from "./articles/11-24-24/News-11-24-24";

const articles = [
    article_11_24_24,
    article_11_17_24,
    article_11_12_24
]

export default articles;